@import "https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap";
* {
  font-family: Moderustic, sans-serif;
}

body {
  background: linear-gradient(90deg, #e0f7fa, #f1f8e9, #ffebee, #f3e5f5) 0 0 / 400% 400%;
  animation: 15s infinite gradient;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.card {
  background: radial-gradient(328px at 2.9% 15%, #bfe0fb 0%, #e8e9fb 25.8%, #fceffa 50.8%, #eafbfb 77.6%, #f0fbf4 100.7%);
  border-radius: 2rem;
  min-height: 100%;
  transition: all 1s;
  position: relative;
}

.card:hover {
  background: radial-gradient(328px at 2.9% 15%, #bfe0fb 0%, #e8e9fb 25.8%, #fceffa 50.8%, #7ce895 77.6%, #f0fbf4 100.7%);
  transform: scale(1.03);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
}
/*# sourceMappingURL=index.a6bc7ba5.css.map */
