@import url("https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap");

* {
  font-family: "Moderustic", sans-serif;
}

body {
  background: linear-gradient(90deg, #e0f7fa, #f1f8e9, #ffebee, #f3e5f5);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.card {
  min-height: 100%;
  border-radius: 2rem;
  background: radial-gradient(
    328px at 2.9% 15%,
    rgb(191, 224, 251) 0%,
    rgb(232, 233, 251) 25.8%,
    rgb(252, 239, 250) 50.8%,
    rgb(234, 251, 251) 77.6%,
    rgb(240, 251, 244) 100.7%
  );
  transition: all ease 1s;
  position: relative;

  &:hover {
    transform: scale(1.03);
    background: radial-gradient(
      328px at 2.9% 15%,
      rgb(191, 224, 251) 0%,
      rgb(232, 233, 251) 25.8%,
      rgb(252, 239, 250) 50.8%,
      rgb(124, 232, 149) 77.6%,
      rgb(240, 251, 244) 100.7%
    );
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  }
}
